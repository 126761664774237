<template>
  <div class="recommond">
    <search-bar />
    <!-- <img :src="topIcon" alt="" /> -->
    <div class="title">频道推荐</div>
    <div
      class="filter-box"
      v-loading="typeLoading"
      element-loading-background="rgb(19, 21, 48)"
    >
      <div v-show="!typeLoading">
        <div
          class="filter-item"
          v-for="(item, index) in filterList"
          :key="index"
        >
          <label class="filter-item-title">{{ item.label }}</label>
          <div class="filter-item-options">
            <div
              class="filter-item-option"
              v-for="(option, index2) in item.options.slice(
                0,
                item.isNeedShowAll ? item.showCount : item.options.length
              )"
              :key="index2"
              @click="clickType(option, index)"
              :class="{ 'filter-item-option-active': item.value == option.id }"
            >
              {{ option.name }}
            </div>
          </div>
          <div
            class="filter-item-button"
            v-show="item.isNeedShowAll"
            @click="clickGetAll(item)"
          >
            <span>{{
              item.showCount == item.options.length ? "收起" : "展开"
            }}</span>
            <img
              :src="
                item.showCount == item.options.length ? selectIcon2 : selectIcon
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="moive-list-history"
      v-loading="loading"
      element-loading-background="rgb(19, 21, 48)"
    >
      <div class="moive-item" v-for="(item, index) in moiveList" :key="index">
        <div class="moive-img"  @click="goDetail(item.videoId)" >
          <!-- <img :src="getImg(item.poster, '200', '288')" alt=""  @click="goDetail(item.videoId)" /> -->
          <lazy-pic :array="[item.poster, '200', '288']" :type="'poster'"   :isWrapper="'a'"  />
          <div class="moive-tag">{{ item.rating.toFixed(1) }}</div>
        </div>
        <div class="moive-title-box">
          <div class="title-1" @click="goDetail(item.videoId)">{{ item.title }}</div>
        </div>
      </div>
      <div v-show="!moiveList.length && !loading" class="no-work">
        <img :src="noworkIcon" alt="" />
        <span>暂无资源</span>
      </div>
    </div>
    <page-select
      v-show="moiveList.length"
      :pagination="pagination"
      @pageChange="pageChange"
    ></page-select>
  </div>
</template>

<script>
import searchBar from "./search-bar";
import pageSelect from "./page-select";
export default {
  name: "recommond",
  data() {
    return {
      // type: "resultEmpty",
      type: "result",
      // type: "normal",

      backgroundImage: require("@assets/index.png"),
      avatarImage: require("@assets/avatar.png"),
      wantIcon: require("@assets/explore/want.png"),
      timeIcon: require("@assets/aside/aside7.png"),
      emptyIcon: require("@assets/explore/empty.png"),
      selectIcon: require("@assets/recommond/select.png"),
      selectIcon2: require("@assets/recommond/select2.png"),
      noworkIcon: require("@assets/detail/empty.png"),

      topIcon: require('@assets/top.png'),
      searchWidth: 0,
      searchPlaceHolder: "复仇者联盟",
      searchVal: "",
      user: {
        name: "胖虎不是铁憨憨",
      },

      activeName: "1",
      moiveList: [],
      filterList: [
        {
          label: "分类",
          options: [
            {
              id: "all",
              name: "全部视频",
            },
          ],
          value: "all",
          isNeedShowAll: false,
          isShowAll: false,
        },
        {
          label: "排序",
          options: [
            {
              id: "all",
              name: "综合排序",
            },
          ],
          value: "all",
          isNeedShowAll: false,
          isShowAll: false,
        },
        {
          label: "类型",
          options: [
            {
              id: "all",
              name: "全部类型",
            },
          ],
          value: "all",
          isNeedShowAll: true,
          showCount: 8,
        },
        {
          label: "地区",
          options: [
            {
              id: "all",
              name: "全部地区",
            },
          ],
          value: "all",
          isNeedShowAll: true,
          showCount: 8,
        },
        {
          label: "年份",
          options: [
            {
              id: "all",
              name: "全部年份",
            },
          ],
          value: "all",
          isNeedShowAll: false,
          isShowAll: false,
        },
      ],
      loading: true,
      pagination: {
        currentPage: 1,
        pageSize: 0,
        numFound: 0,
        totalPage: 0,
      },
      typeLoading: true,
    };
  },
  methods: {
    async init() {
      this.typeLoading = true;
      const result = await this.api.getChannel();
      // this.filterList = result.data
      this.filterList[0].options = this.filterList[0].options.concat(
        result.data.classify
      );
      this.filterList[1].options = this.filterList[1].options.concat(
        result.data.orders
      );
      this.filterList[2].options = this.filterList[2].options.concat(
        result.data.type
      );
      this.filterList[3].options = this.filterList[3].options.concat(
        result.data.location
      );
      this.filterList[4].options = this.filterList[4].options.concat(
        result.data.years
      );

      this.checkRoute(this.$route.params);
      this.typeLoading = false;
      this.$forceUpdate();
      await this.getChannel();
    },
    checkRoute(data) {
      console.log(data);
      if (!Object.values(data).length) return;
      if (data.item.name == "地区") {
        this.filterList[3].value = Number(data.area.areaId);
        console.log(this.filterList[3]);
      } else if (data.item.name == "类型") {
        this.filterList[2].value = Number(data.area.typeId);
      }
    },
    clickType(option, index) {
      this.filterList[index].value = option.id;
      this.getChannel();
    },
    async getChannel() {
      this.loading = true;
      this.moiveList = [];
      let condition = [];
      for (let item of this.filterList) {
        console.log(item.val);
        if (item.value != "all") {
          const selected = item.options.find((i) => i.id === item.value);
          condition.push(selected);
        }
      }
      let httpObj = {
        currentPage: this.pagination.currentPage,
        condition,
      };

      const result = await this.api.getChannelList(httpObj);
      this.pagination = result.data.pagination;
      this.moiveList = result.data.list;

      this.loading = false;
      console.log(result);
    },
    pageChange(e) {
      this.pagination.currentPage = e;
      this.getChannel();
      console.log(e);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    clickGetAll(item) {
      if (item.showCount === item.options.length) {
        item.showCount = 8;
      } else {
        item.showCount = item.options.length;
      }
    },
    clickOptions() {},
  },
  watch: {
    "$store.state.recJump": function (newVal, oldVal) {
	  console.log('我是watch来的数据', newVal)
	  this.checkRoute(newVal)
	  this.getChannel();
    },
  },
  mounted() {
    this.init();
    this.searchWidth = (document.body.offsetWidth - 320) * 0.68 + "px";
  },
  components: {
    searchBar,
    pageSelect,
  },
};
</script>

<style lang="scss">
.recommond {
  background: rgb(19, 21, 48);
  width: calc(100% - 100px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 0 50px;
  .title {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 42px;
    color: #ffffff;
    margin-top: 80px;
    user-select: none;
  }
  .filter-box {
    margin-top: 80px;
    .filter-item {
      margin-bottom: 20px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .filter-item-title {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 18px;
        color: #797a8e;
        padding-top: 10px;
        flex-shrink: 0;
      }
      .filter-item-options {
        display: flex;
        margin-left: 12px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .filter-item-option {
          padding: 10px 20px;

          border-radius: 28px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          cursor: pointer;
          color: #797a8e;
          user-select: none;
        }
        .filter-item-option-active {
          background: #2a1635;
          color: #ff3465;
        }
      }
      .filter-item-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-top: 8px;
        flex-shrink: 0;
        margin-left: 8px;
        font-size: 14px;
        span {
          color: #ff3465;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .no-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    width: 100%;
    img {
      width: 300px;
      height: 210px;
    }
    span {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 21px;
      color: #797a8e;
      margin-top: 20px;
      user-select: none;
    }
  }
  .moive-list-history {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: 250px;
    .moive-item {
     	width: 14%;
				margin-left: 1%;
				margin-right: 1%;
				margin-bottom: 40px;
      .moive-img {
    
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        img {
  
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 10;
        }

        .moive-tag {
          position: absolute;
          right: 16px;
          top: 12px;
          z-index: 11;
          width: 50px;
          height: 30px;
          background: #121330;
          opacity: 0.9;
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #ff3465;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .moive-title-box {
        width: 100%;
        z-index: 11;
        padding-left: 10px;
        margin-top: 10px;
        .title-1 {
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 26px;
          color: #b6b7c3;
        }
        .title-1:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        .title-2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 20px;
          color: #797a8e;
          margin-top: 6px;
        }
        .title-2:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
}

.header-search {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-input {
    input {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(255, 255, 255, 0.1);
      height: 70px;
    }
  }
  .search-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 12px;
    .right-subscribe {
      width: 120px;
      height: 50px;
      border: 2px solid rgba(255, 255, 255, 0.5019607843137255);
      opacity: 1;
      border-radius: 5px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right-avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      margin-left: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.el-tabs__nav-wrap::after {
  display: none;
}
.el-tabs__item {
  width: 80px;
  text-align: center;
  color: rgb(112, 113, 132) !important;
}
.el-tabs__active-bar {
  background-color: #ff3465 !important;
}
.el-tabs__item.is-active {
  color: #ff3465 !important;
}
.el-tabs__item:hover {
  color: #ff3465 !important;
}

@media screen and (max-width: 1680px){
  .recommond {
    .title {
      font-size: 24px;
    }
    .filter-box {
      margin-top: 40px;
      .filter-item {
        .filter-item-options {
          .filter-item-option {
            font-size:12px;
          }
        }
      }
    }
    .moive-list-history {
       .moive-item {
         		width: 16%;
					margin: 0 2%;
					margin-bottom: 20px;
          .moive-img {
            
			margin-right:0;
            img {
              display: block;
            }
          }
          .moive-title-box {
			  padding-left:0;
            .title-1 {
              width: 126px;
			  font-size: 14px;
            }
          }
          .moive-button {
            width: 126px;
            height: 40px;
            img {
              width: 24px;
              height: 24px;
            }
            span {
              font-size: 14px;
            }
          }
        }
    }
  }
}
</style>
